import {createContext, useContext, useCallback, useEffect} from 'react'
import {observer, Observer, useLocalObservable} from 'mobx-react-lite'

export const ModelContext = createContext()
export const useModelContext = () => useContext(ModelContext)
export const parseProxy = data => {
  let response = null

  try {
    response = JSON.parse(JSON.stringify(data))
  } catch (err) {
    response = null
  }

  return response
}

/**
 * Copy props to model.
 * @param {*} props Object containing props to copy. On each call it must have same keys.
 * @param {*} model Model to accept properties, should have all properties contained in props.
 */
export function useCopyPropsToModelEffect(props, model) {
  const propNames = Object.keys(props).sort()
  for (const name of propNames) {
    useEffect(() => {
      model[name] = props[name]
    }, [props[name]])
  }
}

export const useGenerateModel = (modelData, Context = ModelContext) => {
  const model = useLocalObservable(() => modelData)

  const ModelProvider = useCallback(
    ({children}) => <Context.Provider value={{model}}>{children}</Context.Provider>,
    [model]
  )

  const useModelContext = useCallback(() => useContext(Context), [Context])

  return {model, ModelProvider, useModelContext}
}

export {observer, Observer}
